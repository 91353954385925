<template>
  <div class="setting-item s-default-value">
    <esmp-select
      v-model="localSetting.value"
      :placeholder="localSetting.name"
    >
      <esmp-select-option
        v-for="item in defaultValues"
        :value="item.name"
        :key="item.techId"
      >
        {{ item.name }}
      </esmp-select-option>
    </esmp-select>
    <div
      v-if="localSetting.value && localSetting.childs"
      class="setting-item__child-settings"
    >
      <div
        class="setting-item__child-setting setting-item__child-setting--small-offset"
        v-for="child in localSetting.childs"
        :key="child.id"
      >
        <esmp-checkbox
          v-if="child.type === 'checkbox'"
          v-model="child.value"
        >
          {{ child.name }}
        </esmp-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SDefaultValue',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    defaultValues: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
